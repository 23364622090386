<template>
  <div class="inputContainer">
    <label>{{ label }}</label>
    <textarea v-model="value" type="text" @keyup="$emit('update:value', value)" :placeholder="placeholder"/>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Ingrese texto aquí'
    }
  }
};
</script>

<style scoped>
textarea {
  font-family: inherit;
  height: 5rem;
  margin-top: 5px;
  padding: 8px;
  width: 40rem;
}
.inputContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 25px;
}
</style>