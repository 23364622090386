<template>
  <div class="inputContainer">
    <label>{{ label }}</label>
    <input v-model="value" :type="type" @keyup="$emit('update:value', value)" :disabled="disabled">
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      required: true,
    }
  }
};
</script>

<style scoped>
input {
  margin-top: 5px;
  padding: 8px;
  width: 20rem;
}
.inputContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 25px;
}
</style>